import { Component, OnInit, Input } from '@angular/core';
import { formatISO, format } from 'date-fns'

@Component({
  selector: 'app-booked-appointment',
  templateUrl: './booked-appointment.component.html',
  styleUrls: ['./booked-appointment.component.css']
})
export class BookedAppointmentComponent implements OnInit {

  @Input() bookingData: any;
  exectionDate: any;

  constructor() { }

  ngOnInit(): void {
    this.exectionDate = format(new Date(this.bookingData.date), 'dd/MM-yyyy');

  }


}
