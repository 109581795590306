import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {


  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {
    let userData = this.oidcSecurityService.getUserData().subscribe((data) => {
      console.log("CallbackComponent ngOnInit", data);
    });
    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          console.log('callback authenticated', isAuthenticated);
        }
      );  
  }
}
