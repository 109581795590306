import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/Configuration';


@Injectable()
export class ConfigurationService {
	private readonly configUrlPath: string = 'ClientConfiguration';
	private configData!: Configuration;

	// Inject the http service and the app's BASE_URL
	constructor(
		private http: HttpClient) {}

	// Call the ClientConfiguration endpoint, deserialize the response,
	// and store it in this.configData.
	loadConfigurationData(): Promise<Configuration> {
		return this.http.get(`${window.location.origin}/api/${this.configUrlPath}`)
			.toPromise()
			.then((res :any) => {
				this.configData = res;
				return this.configData;
			})
			.catch(err => {
				return Promise.reject(err);
			});
	}

	// A helper property to return the config object
	public get config(): Configuration {
		return this.configData;
	}


  toPromise = (obs : any) =>
  new Promise((resolve, reject) => {
    obs.subscribe({
      complete: resolve,
      error: reject
    });
  });
}
