import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingMainComponent } from './booking-components/booking-main/booking-main.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CallbackComponent } from './callback/callback.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'booking/:appointmentId', component: BookingMainComponent, canActivate: [AutoLoginPartialRoutesGuard], data: { custom: 'param' }, },
  { path: 'callback-oidc', component: CallbackComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
