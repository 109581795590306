import { CustomerService } from './services/customer.service';
import { MessageService } from 'primeng/api';
import { LOCALE_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingContainerComponent } from './booking-components/booking-container/booking-container.component';
import { BookingDaysComponent } from './booking-components/booking-days/booking-days.component';
import { BookingMainComponent } from './booking-components/booking-main/booking-main.component';
import { AppointmentService } from './services/appointment.service';
import { EventBusService } from './services/event-bus-service';
import { TimeSlotService } from './services/time-slot.service';
import { UpgradeService } from './services/upgrade.service';
import { ServersService } from './services/servers.service';
import { WebMedUserService } from './services/webmeduser.service';
import { HttpClientModule } from '@angular/common/http';
import { BookedAppointmentComponent } from './booking-components/booked-appointment/booked-appointment.component';
import { HomeComponent } from './home/home.component';
import { ConfigurationService } from './services/configuration.service';
import { AuthConfigModule } from './auth/auth-config.module';
import { CardModule } from 'primeng/card';
import { CallbackComponent } from './callback/callback.component';

registerLocaleData(localeNorwegian);

@NgModule({
  declarations: [
    AppComponent,
    BookingMainComponent,
    BookingDaysComponent,
    BookingContainerComponent,
    BookedAppointmentComponent,
    HomeComponent,
    CallbackComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthConfigModule,
    CardModule,
  ],
  providers: [

    UpgradeService,
    CustomerService,
    TimeSlotService,
    EventBusService,
    AppointmentService,
    MessageService,
    ConfigurationService,
    ServersService,
    WebMedUserService,
    {
		provide: APP_INITIALIZER,
		useFactory: (configService: ConfigurationService) =>
			() => configService.loadConfigurationData(),
		deps: [ConfigurationService],
		multi: true
    },
    { provide: LOCALE_ID, useValue: 'nb' }

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
